<template>
  <div class="hello">
      <div id='container'>
        <div  id='DmanTip' class="position-item"  >
          <div id='date'>
            Fecha> Nunca UTC
          </div>
          <div id='tip'>
            Llamada de babosa jefe, ver como fluye
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ALICE',
  data () {
    return {
    }
  },
  methods: {
  },
}

</script>
